import { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
// material-ui
import { useTheme, createTheme, ThemeProvider, TextareaAutosize } from '@mui/material/styles';
import {
    Box,
    Button,
    FormHelperText,
    Grid,
    TextField,
    useMediaQuery,
    Alert,
    Snackbar,
    MenuItem,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio
} from '@mui/material';
// third party
import * as Yup from 'yup';
import { useFormikContext, Formik } from 'formik';
import InputMask from 'react-input-mask';
// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';

// assets
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import Loading from 'components/Loading/Loading';
import { getResourceUser, getPartnerPerId, postPartner, updatePartner } from 'services/partners';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const Partner = ({ ...others }) => {
    const params = useParams();
    const themeButton = createTheme({
        status: {
            danger: '#e53e3e'
        },
        palette: {
            primary: {
                main: '#0971f1',
                darker: '#053e85'
            },
            neutral: {
                main: '#64748B',
                contrastText: '#fff'
            }
        }
    });
    const initialValuesEdit = {
        nome_fantasia: '',
        nome_usuario: '',
        razao_social: '',
        cnpj: '',
        mei: '',
        insc_estadual: '',
        insc_municipal: '',
        endereco: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        estado: '',
        cep: '',
        telefone: '',
        email: '',
        email_usuario: '',
        cpf_simples: '',
        contribui_icms: '',
        recolhe_pis: '',
        recolhe_cofins: '',
        recolhe_csll: '',
        tipo_conta: '',
        dados_bancarios: '',
        conta: '',
        observacao: '',
        ativo: ''
    };

    const initialStateOptions = {
        regional: [],
        profile: []
    };
    const theme = useTheme();
    const formik = useFormikContext();
    const navigate = useNavigate();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [valuesEdit, setValuesEdit] = useState(initialValuesEdit);
    const [partner, setPartner] = useState(initialValuesEdit);
    const [options, setOptions] = useState(initialStateOptions);
    const [enderecoViaCep, setEnderecoViaCep] = useState(null);
    const [errorValidate, setErrorValidate] = useState({});
    const isDisabled = params.action === 'view' ? true : false;

    function viewPerId() {
        params.action &&
            getPartnerPerId(params.id)
                .then((resp) => {
                    setPartner(resp.data?.data);
                })
                .catch((e) => {
                    console.log(e);

                    setSuccess('');
                    setError(e.response.data.error);
                    setTimeout(() => {
                        setError('');
                    }, 4000);
                });
    }
    const handleConsultarCEP = async (cep, setFieldValue) => {
        try {
            const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
            const data = await response.json();
            setEnderecoViaCep(data);
            setFieldValue('endereco', data.logradouro);
            setFieldValue('bairro', data.bairro);
            setFieldValue('cidade', data.localidade);
            setFieldValue('estado', data.uf);
        } catch (error) {
            console.error('Erro ao consultar CEP:', error);
        }
    };
    useEffect(() => {
        viewPerId();
    }, [params.id]);
    // useEffect(() => {
    //     getResource();
    // }, []);
    console.log('partner', partner.mei === 0);
    return (
        <>
            <Formik
                initialValues={{
                    nome_fantasia: partner.nome_fantasia || '',
                    nome_usuario: partner.nome_usuario || '',
                    razao_social: partner.razao_social || '',
                    cnpj: partner.cnpj || '',
                    mei: (partner.mei === 0 && 'Não') || (partner.mei === 1 && 'Sim') || '',
                    insc_estadual: partner.insc_estadual || '',
                    insc_municipal: partner.insc_municipal || '',
                    endereco: partner.endereco || '',
                    numero: partner.numero || '',
                    complemento: partner.complemento || '',
                    bairro: partner.bairro || '',
                    cidade: partner.cidade || '',
                    estado: partner.estado || '',
                    cep: partner.cep || '',
                    telefone: partner.telefone || '',
                    email: partner.email || '',
                    email_usuario: partner.email_usuario || '',
                    cpf_simples: (partner.cpf_simples === 0 && 'Não') || (partner.cpf_simples === 1 && 'Sim') || '',
                    contribui_icms: (partner.contribui_icms === 0 && 'Não') || (partner.contribui_icms === 1 && 'Sim') || '',
                    recolhe_pis: (partner.recolhe_pis === 0 && 'Não') || (partner.recolhe_pis === 1 && 'Sim') || '',
                    recolhe_cofins: (partner.recolhe_cofins === 0 && 'Não') || (partner.recolhe_cofins === 1 && 'Sim') || '',
                    recolhe_csll: (partner.recolhe_csll === 0 && 'Não') || (partner.recolhe_csll === 1 && 'Sim') || '',
                    tipo_conta: partner.tipo_conta || '',
                    dados_bancarios: partner.dados_bancarios || '',
                    conta: partner.conta || '',
                    observacao: partner.observacao || '',
                    ativo: partner.ativo || '',
                    created_at: partner.created_at || '',
                    updated_at: partner.updated_at || '',
                    submit: null
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                    razao_social: Yup.string().max(150, 'Máximo de 150 caracteres permitidos').required('Razão Social é obrigatória'),
                    nome_fantasia: Yup.string().max(100, 'Máximo de 100 caracteres permitidos').required('Nome Fantasia é obrigatório'),
                    nome_usuario:
                        params.action === undefined
                            ? Yup.string().max(100, 'Máximo de 100 caracteres permitidos').required('Nome Fantasia é obrigatório')
                            : null,
                    cnpj: Yup.string().required('CNPJ é obrigatório'),
                    mei: Yup.string().required('Campo "Possui MEI?" é obrigatório'),
                    insc_estadual: Yup.string().max(20, 'Máximo de 20 caracteres permitidos').required('Inscrição Estadual é obrigatória'),
                    insc_municipal: Yup.string()
                        .max(20, 'Máximo de 20 caracteres permitidos')
                        .required('Inscrição Municipal é obrigatória'),
                    endereco: Yup.string().max(150, 'Máximo de 150 caracteres permitidos').required('Endereço é obrigatório'),
                    numero: Yup.string().max(45, 'Máximo de 45 caracteres permitidos').required('Número é obrigatório'),
                    complemento: Yup.string().max(45, 'Máximo de 45 caracteres permitidos').required('Complemento é obrigatório'),
                    bairro: Yup.string().max(45, 'Máximo de 45 caracteres permitidos').required('Bairro é obrigatório'),
                    cidade: Yup.string().max(50, 'Máximo de 50 caracteres permitidos').required('Cidade é obrigatória'),
                    estado: Yup.string().required('Estado é obrigatório'),
                    cep: Yup.string().max(9, 'Máximo de 8 caracteres permitidos').required('CEP é obrigatório'),
                    telefone: Yup.string().required('Telefone é obrigatório'),
                    email: Yup.string()
                        .email('Email inválido')
                        .max(100, 'Máximo de 100 caracteres permitidos')
                        .required('Email é obrigatório'),
                    email_usuario:
                        params.action === undefined
                            ? Yup.string()
                                  .email('Email inválido')
                                  .max(100, 'Máximo de 100 caracteres permitidos')
                                  .required('Email é obrigatório')
                            : null,
                    cpf_simples: Yup.string().required('Campo "Optante pelo Simples Nacional" é obrigatório'),
                    contribui_icms: Yup.string().required('Campo "Contribuinte de ICMS" é obrigatório'),
                    dados_bancarios: Yup.string().required('Campo Dados bancários é obrigatório'),
                    recolhe_pis: Yup.string().required('Campo "Recolhe PIS" é obrigatório'),
                    recolhe_cofins: Yup.string().required('Campo "Recolhe COFINS" é obrigatório'),
                    recolhe_csll: Yup.string().required('Campo "Recolhe CSLL" é obrigatório'),
                    tipo_conta: Yup.string().nullable().required('Tipo de Conta é obrigatório'),
                    conta: Yup.string().nullable().required('Conta é obrigatória'),
                    // obs: Yup.string().max(250, 'Máximo de 250 caracteres permitidos').required('Observações são obrigatórias'),
                    ativo: Yup.boolean().default(true).required('Campo "Ativo" é obrigatório')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm, setFieldValue }) => {
                    try {
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                        setLoading(true);
                        values.cep = values.cep.replace(/\D/g, '');
                        values.telefone = values.telefone.replace(/\D/g, '');
                        values.cnpj = values.cnpj.replace(/\D/g, '');
                        values.mei = values.mei === 'Sim' ? 1 : 0;
                        values.cpf_simples = values.cpf_simples === 'Sim' ? 1 : 0;
                        values.contribui_icms = values.contribui_icms === 'Sim' ? 1 : 0;
                        values.recolhe_pis = values.recolhe_pis === 'Sim' ? 1 : 0;
                        values.recolhe_cofins = values.recolhe_cofins === 'Sim' ? 1 : 0;
                        values.recolhe_csll = values.recolhe_csll === 'Sim' ? 1 : 0;
                        if (params.action === 'edit') {
                            updatePartner(params.id, values)
                                .then((resp) => {
                                    setError('');
                                    setLoading(false);
                                    viewPerId();
                                    setSuccess(resp.data.success);
                                    setTimeout(() => {
                                        setSuccess('');
                                    }, 3000);
                                })
                                .catch((e) => {
                                    setLoading(false);
                                    setSuccess('');
                                    setErrorValidate(e.response.data.errorValidate);
                                    setError(e.response.data.error);
                                    setTimeout(() => {
                                        setError('');
                                    }, 3000);
                                });
                        } else {
                            values.ativo = 1;
                            postPartner(values)
                                .then((resp) => {
                                    setError('');
                                    setLoading(false);
                                    setSuccess(resp.data.success);
                                    setTimeout(() => {
                                        resetForm();
                                        setSuccess('');
                                    }, 2000);
                                })
                                .catch((e) => {
                                    console.log(e);
                                    setLoading(false);
                                    setSuccess('');

                                    if (e.response) {
                                        const { errorValidate, errors, error } = e.response.data;

                                        if (errorValidate) {
                                            setErrorValidate(errorValidate);
                                        }

                                        if (errors) {
                                            const errorMessages = Object.values(errors).flat();
                                            setError(errorMessages[0]);
                                            for (const campo in errors) {
                                                if (errors.hasOwnProperty(campo)) {
                                                    const mensagensErroCampo = errors[campo];
                                                    console.log(`${campo}: ${mensagensErroCampo.join(', ')}`);
                                                }
                                            }
                                        }

                                        console.log('Status da resposta de erro:', e.response.status);
                                    } else if (e.request) {
                                        console.log('Requisição feita, mas sem resposta');
                                        setError('Erro: Sem resposta do servidor');
                                    } else {
                                        console.log('Erro ao processar a requisição:', e.message);
                                        setError('Ocorreu um erro ao processar a solicitação.');
                                    }
                                });
                        }
                    } catch (err) {
                        console.error(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <>
                        {loading && (
                            <Grid container alignItems="center" justifyContent="center">
                                <MainCard style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Loading color="#015641" type="cubes" />
                                </MainCard>
                            </Grid>
                        )}
                        <MainCard spacing={gridSpacing} style={{ margin: 25 }}>
                            <Grid xs={12} md={12} sm={12} container>
                                {params?.action === 'view' ? (
                                    <h1
                                        style={{
                                            font: 'normal normal bold 35px/44px',
                                            letterSpacing: '0px',
                                            opacity: 1
                                        }}
                                    >
                                        Visualizar Parceiro
                                    </h1>
                                ) : params?.action === 'edit' ? (
                                    <h1
                                        style={{
                                            font: 'normal normal bold 35px/44px',
                                            letterSpacing: '0px',
                                            opacity: 1
                                        }}
                                    >
                                        Editar Parceiro
                                    </h1>
                                ) : (
                                    <h1
                                        style={{
                                            font: 'normal normal bold 35px/44px',
                                            letterSpacing: '0px',
                                            opacity: 1
                                        }}
                                    >
                                        Novo Parceiro
                                    </h1>
                                )}

                                <hr style={{ width: '100%', marginTop: 0 }}></hr>
                            </Grid>
                            {error || alert ? (
                                <Snackbar open={true} autoHideDuration={6000}>
                                    <Alert
                                        severity={error ? 'error' : success ? 'success' : ''}
                                        sx={{
                                            width: '100%',
                                            backgroundColor: error ? 'red' : success ? 'green' : 'orange',
                                            color: '#FFF'
                                        }}
                                    >
                                        {error ? error : success ? success : ''}
                                    </Alert>
                                </Snackbar>
                            ) : (
                                ''
                            )}
                            <div style={{ display: loading ? 'none' : 'block' }}>
                                <form noValidate onSubmit={handleSubmit} {...others}>
                                    <Grid container spacing={matchDownSM ? 0 : 2}>
                                        <Grid item xs={4} sm={4} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={
                                                    Boolean(touched.nome_fantasia && errors.nome_fantasia) ||
                                                    Boolean(errorValidate.nome_fantasia)
                                                }
                                                id="outlined-nome-fantasia"
                                                type="text"
                                                label="Nome Fantasia"
                                                value={values?.nome_fantasia}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="nome_fantasia"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.nome_fantasia && errors.nome_fantasia
                                                        ? errors.nome_fantasia
                                                        : errorValidate.nome_fantasia
                                                        ? errorValidate.nome_fantasia
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={4} sm={4} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={
                                                    Boolean(touched.razao_social && errors.razao_social) ||
                                                    Boolean(errorValidate.razao_social)
                                                }
                                                id="outlined-razao-social"
                                                type="text"
                                                label="Razão Social"
                                                value={values?.razao_social}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="razao_social"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.razao_social && errors.razao_social
                                                        ? errors.razao_social
                                                        : errorValidate.razao_social
                                                        ? errorValidate.razao_social
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={4} sm={4} sx={{ marginTop: 3 }}>
                                            <InputMask
                                                mask="99.999.999/9999-99"
                                                value={values?.cnpj}
                                                disabled={isDisabled}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                                {(inputProps) => (
                                                    <TextField
                                                        {...inputProps}
                                                        disabled={isDisabled}
                                                        fullWidth
                                                        error={Boolean(touched.cnpj && errors.cnpj) || Boolean(errorValidate.cnpj)}
                                                        id="outlined-cnpj"
                                                        type="text"
                                                        label="CNPJ"
                                                        name="cnpj"
                                                        helperText={
                                                            touched.cnpj && errors.cnpj
                                                                ? errors.cnpj
                                                                : errorValidate.cnpj
                                                                ? errorValidate.cnpj
                                                                : ''
                                                        }
                                                    />
                                                )}
                                            </InputMask>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={matchDownSM ? 0 : 2}>
                                        <Grid item xs={6} sm={6} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={
                                                    Boolean(touched.insc_estadual && errors.insc_estadual) ||
                                                    Boolean(errorValidate.insc_estadual)
                                                }
                                                id="outlined-insc-estadual"
                                                type="number"
                                                label="Inscrição Estadual"
                                                value={values?.insc_estadual}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="insc_estadual"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.insc_estadual && errors.insc_estadual
                                                        ? errors.insc_estadual
                                                        : errorValidate.insc_estadual
                                                        ? errorValidate.insc_estadual
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={
                                                    Boolean(touched.insc_municipal && errors.insc_municipal) ||
                                                    Boolean(errorValidate.insc_municipal)
                                                }
                                                id="outlined-insc-municipal"
                                                type="text"
                                                label="Inscrição Municipal"
                                                value={values?.insc_municipal}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="insc_municipal"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.insc_municipal && errors.insc_municipal
                                                        ? errors.insc_municipal
                                                        : errorValidate.insc_municipal
                                                        ? errorValidate.insc_municipal
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={matchDownSM ? 0 : 2}>
                                        <Grid item xs={4} sm={4} sx={{ marginTop: 3 }}>
                                            <InputMask
                                                mask="99999-999"
                                                value={values?.cep}
                                                disabled={isDisabled}
                                                onChange={handleChange}
                                                onBlur={(e) => handleConsultarCEP(e.target.value, setFieldValue)}
                                            >
                                                {(inputProps) => (
                                                    <TextField
                                                        {...inputProps}
                                                        fullWidth
                                                        disabled={isDisabled}
                                                        error={Boolean(touched.cep && errors.cep) || Boolean(errorValidate.cep)}
                                                        id="outlined-cep"
                                                        type="text"
                                                        label="CEP"
                                                        name="cep"
                                                        helperText={
                                                            touched.cep && errors.cep
                                                                ? errors.cep
                                                                : errorValidate.cep
                                                                ? errorValidate.cep
                                                                : ''
                                                        }
                                                    />
                                                )}
                                            </InputMask>
                                        </Grid>
                                        <Grid item xs={4} sm={4} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.endereco && errors.endereco) || Boolean(errorValidate.endereco)}
                                                id="outlined-endereco"
                                                type="text"
                                                label="Endereço"
                                                value={values?.endereco}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="endereco"
                                                disabled={true}
                                                helperText={
                                                    touched.endereco && errors.endereco
                                                        ? errors.endereco
                                                        : errorValidate.endereco
                                                        ? errorValidate.endereco
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={4} sm={4} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.bairro && errors.bairro) || Boolean(errorValidate.bairro)}
                                                id="outlined-bairro"
                                                type="text"
                                                label="Bairro"
                                                disabled
                                                value={values?.bairro}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="bairro"
                                                helperText={
                                                    touched.bairro && errors.bairro
                                                        ? errors.bairro
                                                        : errorValidate.bairro
                                                        ? errorValidate.bairro
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={matchDownSM ? 0 : 2}>
                                        <Grid item xs={4} sm={4} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.cidade && errors.cidade) || Boolean(errorValidate.cidade)}
                                                id="outlined-cidade"
                                                type="text"
                                                label="Cidade"
                                                disabled
                                                value={values?.cidade}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="cidade"
                                                helperText={
                                                    touched.cidade && errors.cidade
                                                        ? errors.cidade
                                                        : errorValidate.cidade
                                                        ? errorValidate.cidade
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={4} sm={4} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.estado && errors.estado) || Boolean(errorValidate.estado)}
                                                id="outlined-estado"
                                                type="text"
                                                label="Estado"
                                                disabled
                                                value={values?.estado}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="estado"
                                                helperText={
                                                    touched.estado && errors.estado
                                                        ? errors.estado
                                                        : errorValidate.estado
                                                        ? errorValidate.estado
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={2} sm={2} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.numero && errors.numero) || Boolean(errorValidate.numero)}
                                                id="outlined-numero"
                                                type="number"
                                                label="Número"
                                                value={values?.numero}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="numero"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.numero && errors.numero
                                                        ? errors.numero
                                                        : errorValidate.numero
                                                        ? errorValidate.numero
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={2} sm={2} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={
                                                    Boolean(touched.complemento && errors.complemento) || Boolean(errorValidate.complemento)
                                                }
                                                id="outlined-complemento"
                                                type="text"
                                                label="Complemento"
                                                value={values?.complemento}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="complemento"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.complemento && errors.complemento
                                                        ? errors.complemento
                                                        : errorValidate.complemento
                                                        ? errorValidate.complemento
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={matchDownSM ? 0 : 2}>
                                        <Grid item xs={6} sm={6} sx={{ marginTop: 3 }}>
                                            {/* <TextField
                                                fullWidth
                                                error={Boolean(touched.telefone && errors.telefone) || Boolean(errorValidate.telefone)}
                                                id="outlined-telefone"
                                                type="text"
                                                label="Telefone"
                                                value={values?.telefone}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="telefone"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.telefone && errors.telefone
                                                        ? errors.telefone
                                                        : errorValidate.telefone
                                                        ? errorValidate.telefone
                                                        : ''
                                                }
                                            /> */}
                                            <InputMask
                                                mask="(99) 99999-9999"
                                                value={values?.telefone}
                                                disabled={isDisabled}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                                {(inputProps) => (
                                                    <TextField
                                                        {...inputProps}
                                                        disabled={isDisabled}
                                                        fullWidth
                                                        error={
                                                            Boolean(touched.telefone && errors.telefone) || Boolean(errorValidate.telefone)
                                                        }
                                                        id="outlined-telefone"
                                                        type="text"
                                                        label="Telefone"
                                                        name="telefone"
                                                        helperText={
                                                            touched.telefone && errors.telefone
                                                                ? errors.telefone
                                                                : errorValidate.telefone
                                                                ? errorValidate.telefone
                                                                : ''
                                                        }
                                                    />
                                                )}
                                            </InputMask>
                                        </Grid>
                                        <Grid item xs={6} sm={6} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.email && errors.email) || Boolean(errorValidate.email)}
                                                id="outlined-email"
                                                type="text"
                                                label="E-mail"
                                                value={values?.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="email"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.email && errors.email
                                                        ? errors.email
                                                        : errorValidate.email
                                                        ? errorValidate.email
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={matchDownSM ? 0 : 2}>
                                        <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                                            <FormControl component="fieldset">
                                                <FormLabel style={{ marginBottom: '-5px' }} component="legend">
                                                    Possui MEI?
                                                </FormLabel>
                                                <RadioGroup
                                                    row
                                                    aria-label="possui-mei"
                                                    name="mei"
                                                    disabled={isDisabled}
                                                    value={values?.mei}
                                                    onChange={(e) => setFieldValue('mei', e.target.value)}
                                                >
                                                    <FormControlLabel disabled={isDisabled} value={'Sim'} control={<Radio />} label="Sim" />
                                                    <FormControlLabel disabled={isDisabled} value={'Não'} control={<Radio />} label="Não" />
                                                </RadioGroup>
                                                {errors.mei && touched.mei && (
                                                    <div>
                                                        {<p style={{ color: 'red', fontSize: '13px', marginTop: '-10px' }}>{errors.mei}</p>}
                                                    </div>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    {console.log('errorValidate', errors)}
                                    <Grid container spacing={matchDownSM ? 0 : 1}>
                                        <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                                            <FormControl component="fieldset">
                                                <FormLabel style={{ marginBottom: '-5px' }} component="legend">
                                                    Optante pelo simples?
                                                </FormLabel>
                                                <RadioGroup
                                                    row
                                                    disabled={isDisabled}
                                                    aria-label="cpf-simples"
                                                    name="cpf_simples"
                                                    value={values?.cpf_simples}
                                                    onChange={(e) => setFieldValue('cpf_simples', e.target.value)}
                                                >
                                                    <FormControlLabel disabled={isDisabled} value={'Sim'} control={<Radio />} label="Sim" />
                                                    <FormControlLabel disabled={isDisabled} value={'Não'} control={<Radio />} label="Não" />
                                                </RadioGroup>
                                                {errors.cpf_simples && touched.cpf_simples && (
                                                    <div>
                                                        {
                                                            <p style={{ color: 'red', fontSize: '13px', marginTop: '-10px' }}>
                                                                {errors.cpf_simples}
                                                            </p>
                                                        }
                                                    </div>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={matchDownSM ? 0 : 1}>
                                        <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                                            <FormControl component="fieldset">
                                                <FormLabel style={{ marginBottom: '-5px' }} component="legend">
                                                    Contribuído ICMS?
                                                </FormLabel>
                                                {console.log('isDisabled', values)}
                                                <RadioGroup
                                                    row
                                                    disabled={isDisabled}
                                                    aria-label="contribui-icms"
                                                    name="contribui_icms"
                                                    value={values?.contribui_icms}
                                                    onChange={(e) => setFieldValue('contribui_icms', e.target.value)}
                                                >
                                                    <FormControlLabel disabled={isDisabled} value={'Sim'} control={<Radio />} label="Sim" />
                                                    <FormControlLabel disabled={isDisabled} value={'Não'} control={<Radio />} label="Não" />
                                                </RadioGroup>
                                                {errors.contribui_icms && touched.contribui_icms && (
                                                    <div>
                                                        {
                                                            <p style={{ color: 'red', fontSize: '13px', marginTop: '-10px' }}>
                                                                {errors.contribui_icms}
                                                            </p>
                                                        }
                                                    </div>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={matchDownSM ? 0 : 1}>
                                        <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                                            <FormControl component="fieldset">
                                                <FormLabel style={{ marginBottom: '-5px' }} component="legend">
                                                    Fornecedor responsável pelo recolhimento do PIS?
                                                </FormLabel>
                                                <RadioGroup
                                                    row
                                                    disabled={isDisabled}
                                                    aria-label="recolhe-pis"
                                                    name="recolhe_pis"
                                                    value={values?.recolhe_pis}
                                                    onChange={(e) => setFieldValue('recolhe_pis', e.target.value)}
                                                >
                                                    <FormControlLabel disabled={isDisabled} value={'Sim'} control={<Radio />} label="Sim" />
                                                    <FormControlLabel disabled={isDisabled} value={'Não'} control={<Radio />} label="Não" />
                                                </RadioGroup>
                                            </FormControl>
                                            {errors.recolhe_pis && touched.recolhe_pis && (
                                                <div>
                                                    {
                                                        <p style={{ color: 'red', fontSize: '13px', marginTop: '-10px' }}>
                                                            {errors.recolhe_pis}
                                                        </p>
                                                    }
                                                </div>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={matchDownSM ? 0 : 1}>
                                        <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                                            <FormControl component="fieldset">
                                                <FormLabel style={{ marginBottom: '-5px' }} component="legend">
                                                    Fornecedor responsável pelo recolhimento do COFINS?
                                                </FormLabel>
                                                <RadioGroup
                                                    row
                                                    disabled={isDisabled}
                                                    aria-label="recolhe-cofins"
                                                    name="recolhe_cofins"
                                                    value={values?.recolhe_cofins}
                                                    onChange={(e) => setFieldValue('recolhe_cofins', e.target.value)}
                                                >
                                                    <FormControlLabel disabled={isDisabled} value={'Sim'} control={<Radio />} label="Sim" />
                                                    <FormControlLabel disabled={isDisabled} value={'Não'} control={<Radio />} label="Não" />
                                                </RadioGroup>
                                                {errors.recolhe_cofins && touched.recolhe_cofins && (
                                                    <div>
                                                        {
                                                            <p style={{ color: 'red', fontSize: '13px', marginTop: '-10px' }}>
                                                                {errors.recolhe_cofins}
                                                            </p>
                                                        }
                                                    </div>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={matchDownSM ? 0 : 1}>
                                        <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                                            <FormControl component="fieldset">
                                                <FormLabel style={{ marginBottom: '-5px' }} component="legend">
                                                    Fornecedor responsável pelo recolhimento do CSLL?
                                                </FormLabel>
                                                <RadioGroup
                                                    row
                                                    disabled={isDisabled}
                                                    aria-label="recolhe_csll"
                                                    name="recolhe_csll"
                                                    value={values?.recolhe_csll}
                                                    onChange={(e) => setFieldValue('recolhe_csll', e.target.value)}
                                                >
                                                    <FormControlLabel disabled={isDisabled} value={'Sim'} control={<Radio />} label="Sim" />
                                                    <FormControlLabel disabled={isDisabled} value={'Não'} control={<Radio />} label="Não" />
                                                </RadioGroup>
                                                {errors.recolhe_csll && touched.recolhe_csll && (
                                                    <div>
                                                        {
                                                            <p style={{ color: 'red', fontSize: '13px', marginTop: '-10px' }}>
                                                                {errors.recolhe_csll}
                                                            </p>
                                                        }
                                                    </div>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={matchDownSM ? 0 : 1}>
                                        <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                                            <FormControl component="fieldset">
                                                <FormLabel style={{ marginBottom: '-5px' }} component="legend">
                                                    Conta
                                                </FormLabel>
                                                <RadioGroup
                                                    row
                                                    disabled={isDisabled}
                                                    aria-label="conta"
                                                    name="conta"
                                                    value={values?.conta}
                                                    onChange={(e) => setFieldValue('conta', e.target.value)}
                                                >
                                                    <FormControlLabel
                                                        disabled={isDisabled}
                                                        value={'Pessoa Jurídica'}
                                                        control={<Radio />}
                                                        label="Pessoa Jurídica"
                                                    />
                                                    <FormControlLabel
                                                        disabled={isDisabled}
                                                        value={'Pessoa Física'}
                                                        control={<Radio />}
                                                        label="Pessoa Física"
                                                    />
                                                </RadioGroup>
                                                {errors.conta && touched.conta && (
                                                    <div>
                                                        {
                                                            <p style={{ color: 'red', fontSize: '13px', marginTop: '-10px' }}>
                                                                {errors.conta}
                                                            </p>
                                                        }
                                                    </div>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={matchDownSM ? 0 : 1}>
                                        <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                                            <FormControl component="fieldset">
                                                <FormLabel style={{ marginBottom: '-5px' }} component="legend">
                                                    Tipo de conta
                                                </FormLabel>
                                                <RadioGroup
                                                    row
                                                    disabled={isDisabled}
                                                    aria-label="tipo_conta"
                                                    name="tipo_conta"
                                                    value={values?.tipo_conta}
                                                    onChange={(e) => setFieldValue('tipo_conta', e.target.value)}
                                                >
                                                    <FormControlLabel
                                                        disabled={isDisabled}
                                                        value={'Corrente'}
                                                        control={<Radio />}
                                                        label="Corrente"
                                                    />
                                                    <FormControlLabel
                                                        disabled={isDisabled}
                                                        value={'Poupança'}
                                                        control={<Radio />}
                                                        label="Poupança"
                                                    />
                                                </RadioGroup>
                                                {errors.tipo_conta && touched.tipo_conta && (
                                                    <div>
                                                        {
                                                            <p style={{ color: 'red', fontSize: '13px', marginTop: '-10px' }}>
                                                                {errors.tipo_conta}
                                                            </p>
                                                        }
                                                    </div>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={matchDownSM ? 0 : 2}>
                                        <Grid item xs={6} sm={6} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                multiline
                                                minRows={4}
                                                maxRows={10}
                                                error={
                                                    Boolean(touched.dados_bancarios && errors.dados_bancarios) ||
                                                    Boolean(errorValidate.dados_bancarios)
                                                }
                                                id="outlined-dados-bancarios"
                                                label="Dados bancários"
                                                value={values?.dados_bancarios}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="dados_bancarios"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.dados_bancarios && errors.dados_bancarios
                                                        ? errors.dados_bancarios
                                                        : errorValidate.dados_bancarios
                                                        ? errorValidate.dados_bancarios
                                                        : ''
                                                }
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </Grid>
                                    {params.action == undefined && (
                                        <Grid container spacing={matchDownSM ? 0 : 2}>
                                            <Grid item xs={6} sm={6} sx={{ marginTop: 3 }}>
                                                <TextField
                                                    fullWidth
                                                    error={
                                                        Boolean(touched.nome_usuario && errors.nome_usuario) ||
                                                        Boolean(errorValidate.nome_usuario)
                                                    }
                                                    id="outlined-nome_usuario"
                                                    type="text"
                                                    label="Nome do responsável"
                                                    value={values?.nome_usuario}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="nome_usuario"
                                                    disabled={isDisabled}
                                                    helperText={
                                                        touched.nome_usuario && errors.nome_usuario
                                                            ? errors.nome_usuario
                                                            : errorValidate.nome_usuario
                                                            ? errorValidate.nome_usuario
                                                            : ''
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={6} sx={{ marginTop: 3 }}>
                                                <TextField
                                                    fullWidth
                                                    error={
                                                        Boolean(touched.email_usuario && errors.email_usuario) ||
                                                        Boolean(errorValidate.email_usuario)
                                                    }
                                                    id="outlined-email_usuario"
                                                    type="text"
                                                    label="E-mail do responsável"
                                                    value={values?.email_usuario}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="email_usuario"
                                                    disabled={isDisabled}
                                                    helperText={
                                                        touched.email_usuario && errors.email_usuario
                                                            ? errors.email_usuario
                                                            : errorValidate.email_usuario
                                                            ? errorValidate.email_usuario
                                                            : ''
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    )}

                                    <Grid container alignItems="end" justifyContent="end" sx={{ mt: 3 }}>
                                        <Grid item>
                                            <Box sx={{ mt: 2, mr: 3 }}>
                                                <ThemeProvider theme={themeButton}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            disabled={isSubmitting}
                                                            fullWidth
                                                            size="large"
                                                            type="button"
                                                            variant="contained"
                                                            color="neutral"
                                                            onClick={() => navigate(-1)}
                                                        >
                                                            Voltar
                                                        </Button>
                                                    </AnimateButton>
                                                </ThemeProvider>
                                            </Box>
                                        </Grid>
                                        {params.action === 'view' ? (
                                            <Grid item>
                                                <Box sx={{ mt: 2, mr: 3 }}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            // disabled={isSubmitting}
                                                            component={Link}
                                                            to={`/parceiro/${params.id}/edit`}
                                                            fullWidth
                                                            size="large"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Editar
                                                        </Button>
                                                    </AnimateButton>
                                                </Box>
                                            </Grid>
                                        ) : (
                                            <Grid item>
                                                <Box sx={{ mt: 2, mr: 3 }}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            disabled={isSubmitting}
                                                            fullWidth
                                                            size="large"
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Salvar
                                                        </Button>
                                                    </AnimateButton>
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                    {errors.submit && (
                                        <Box sx={{ mt: 3 }}>
                                            <FormHelperText error>{errors.submit}</FormHelperText>
                                        </Box>
                                    )}
                                </form>
                            </div>
                        </MainCard>
                    </>
                )}
            </Formik>
        </>
    );
};

export default Partner;
