import { combineReducers } from 'redux';
import authReducer from './authReducer';

// reducer import
import customizationReducer from './customizationReducer';
import operatingReducer from './operatingReducer';
import usersReducer from './usersReducer';
import partnersReducer from './partnersReducer';
import contractsReducer from './contractsReducer';
import staffsReducer from './staffsReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    auth: authReducer,
    users: usersReducer,
    operating: operatingReducer,
    partners: partnersReducer,
    contracts: contractsReducer,
    staffs: staffsReducer
});

export default reducer;
